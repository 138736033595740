import React, { FC, useEffect, useState } from 'react'
import { Button, Form, Input, InputNumber, Select, Spin, Space, Alert } from 'antd'
import { ISelect } from '../../../../../config/data.interface'
import { useProductWarList } from '../../../../../services/product/product.service'
import { userProductStock } from '../../../../../services/warehouse-sku-all/warehouse-sku-all.service'
import { IWarehouseSkuAllResponse } from '../../../../../services/warehouse-sku-all/warehouse-sku-all.interface'
import { IProductResponse, ISkuItemResponse } from '../../../../../services/product/product.interface'
import { IProductSearchSchema } from '../../../../../services/product/product.schema'
import { useCompanyStore } from '../../../../../services/company-create/company-create.service'
import { useUserMasterAdmin } from '../../../../../services/order-create/order.edit'

const { Option } = Select

interface IProductSupplierSearchFormProps {
  onSubmit: (data: IProductSearchSchema) => void
  warhSelected: (warName: string) => void
}

const ProductSupplierAgencySearch: FC<IProductSupplierSearchFormProps> = ({ onSubmit, warhSelected }) => {
  const [form] = Form.useForm<IProductSearchSchema>()
  const { getProductsWar, productsWar, loading } = useProductWarList()
  const { getProductStock, productStock } = userProductStock()
  const { companyGlobal } = useCompanyStore()
  const [productList, setProductList] = useState<ISelect[]>([])
  const [variantList, setVariantList] = useState<ISelect[]>([])
  const [productSelect, setProductSelect] = useState<IProductResponse>()
  const [skuSelect, setSkuSelect] = useState<ISkuItemResponse>()
  const [warehouseSelected, setWarehouseSelected] = useState<ISelect>()
  const [ warehouseSkuSelect, setWarehouseSkuSelect] = useState<IWarehouseSkuAllResponse | null>()
  const { rolUserMaster } = useUserMasterAdmin()
  const warehousesData: ISelect[] = [
    { value: 41, label: 'STOKEALO' },
    { value: 137, label: 'ALIPARTNER' },
    { value: 118, label: 'EVERMONT' },
    //{ value: 128, label: 'VOCH' },
    { value: 126, label: 'YUZU' },
    { value: 127, label: 'FENG FU' },
    { value: 23, label: 'WANKLIC GROUP' },
    { value: 130, label: 'FENIX' },
    //{ value: 131, label: 'FABI IMPORTACIONES' },
    { value: 132, label: 'DROP HOUSE' },
    { value: 134, label: 'Shoppedi2' },
    { value: 136, label: 'ALFALION INVESTMENT' },
    { value: 135, label: 'SMARTBUY' },
    { value: 138, label: 'SOMOS TVL' },
    { value: 141, label: 'N-WOW' },
    { value: 148, label: 'AleVilla Importaciones' },
  ]

  const optionsId = Form.useWatch('optionsId', form)
  const quantity = Form.useWatch('quantity', form)
  const subtotal = Form.useWatch('subtotal', form)
  const price = Form.useWatch('price', form)

  const handleWarehouseSelected = (warehouseId: number) => {

    const war = warehousesData.find(w => w.value === warehouseId)

    if (war) {
      form.setFieldValue('warehouseId', war.value)
      setSkuSelect(undefined)
      form.setFieldValue('productId', undefined)
    }
    setWarehouseSelected(war)
  }

  const handleChangeProduct = (productId: number) => {
    const product = productsWar?.find((p) => p.id === productId)
    const variants = product?.variants.map((v) => ({
      label: v.name,
      value: v.variantId,
      options: v.options.map((o) => ({
        label: o.name,
        value: o.optionId
      }))
    }))

    setProductSelect(product)
    setVariantList(variants as ISelect[])
    if (variants?.length === 0) {
      const sku = product?.skus[0]
      
      form.setFieldValue('productName', `${productSelect?.name} (${sku?.company?.setting?.companyPrefix})`)
      form.setFieldValue('dropPrice', productSelect?.salePriceDrop)
      form.setFieldValue('sku', sku)
      form.setFieldValue('skuId', sku?.id)
      form.setFieldValue('price', productSelect?.retailPriceSuggested)
      form.setFieldValue('subtotal', productSelect ? productSelect?.retailPriceSuggested * quantity : 0)
      form.setFieldValue('companyId', sku?.companyId)
      setSkuSelect(sku)
    }
  }

  useEffect(() => {
    form.setFieldValue('quantity', 1)
  }, [])

  useEffect(() => {
    if (productsWar) {
      setProductList(productsWar.map((e: IProductResponse) => ({
        label: `${e.name} (${e.skus[0].company.setting.companyPrefix})`,
        value: e.id,
      })))
    }
  }, [productsWar])

  useEffect(() => {
    if (optionsId) {
      const optionsIdArray: string[] = []

      for (const key in optionsId) {
        if (Object.prototype.hasOwnProperty.call(optionsId, key)) {
          const element = optionsId[key]

          element && optionsIdArray.push(optionsId[key])
        }
      }

      const skuFound = productSelect?.skus?.find((sku) => {
        const skuOptionsArray = sku.skuOptions.map(s => s.optionId)

        return optionsIdArray.sort().toString() == skuOptionsArray.sort().toString()
      })

      //form.setFieldsValue({ warehouseId: undefined })
      form.setFieldsValue({ quantity: 1 })

      // Obtener nombre de options desde variantList
      const myOptions = variantList
        .map(e => ({
          variant: e.label,
          option: e.options.find((p: ISelect) => optionsIdArray.includes(p.value as string))?.label
        }))

      // const productName = `${productSelect?.name} / ${myOptions.map(e => `${e.variant} ${e.option}`).join(' / ')}`
      const productName = `${productSelect?.name} ${myOptions.map(e => `${e.option}`).join(' ')}`

      // Setear en fields
      form.setFieldValue('productName', `${productName} (${skuFound?.company?.setting?.companyPrefix})`)
      form.setFieldValue('dropPrice', productSelect?.salePriceDrop)
      form.setFieldValue('sku', skuFound)
      form.setFieldValue('skuId', skuFound?.id)
      form.setFieldValue('price', productSelect?.retailPriceSuggested)
      form.setFieldValue('subtotal', productSelect?.retailPriceSuggested)
      form.setFieldValue('companyId', skuFound?.companyId)
      setSkuSelect(skuFound)
    }
  }, [optionsId])

  useEffect(() => {
    const optionsIdArray: string[] = []

    for (const key in optionsId) {
      if (Object.prototype.hasOwnProperty.call(optionsId, key)) {
        const element = optionsId[key]

        element && optionsIdArray.push(optionsId[key])
      }
    }
    const myOptions = variantList
      .map(e => ({
        variant: e.label,
        option: e.options.find((p: ISelect) => optionsIdArray.includes(p.value as string))?.label
      }))

    const productName = `${productSelect?.name} ${myOptions.map(e => `${e.option}`).join(' ')}`

    form.setFieldValue('productName', `${productName} (${skuSelect?.company?.setting?.companyPrefix})`)
    form.setFieldValue('dropPrice', productSelect?.salePriceDrop)
    if (subtotal > 0) {
      form.setFieldValue('price', (subtotal / quantity).toFixed(2))
    } else {
      form.setFieldValue('price', productSelect?.retailPriceSuggested)
    }
    form.setFieldValue('companyId', skuSelect?.companyId)
  }, [quantity, subtotal])

  useEffect(() => {
    setWarehouseSkuSelect(null)
    if (skuSelect) {

      getProductStock(String(`${warehouseSelected?.value}`), String(skuSelect.id), String(skuSelect.companyId))

    }
  }, [skuSelect])

  useEffect(() => {
    if (productStock) {
      setWarehouseSkuSelect(productStock)
    }
  }, [productStock])

  useEffect(() => {
    if (productSelect && productSelect.variants.length == 0) {
      form.setFieldValue('quantity', 1)
      form.setFieldValue('price', productSelect?.retailPriceSuggested)
      form.setFieldValue('subtotal', productSelect?.retailPriceSuggested)
      form.setFieldValue('companyId', skuSelect?.companyId)
      form.setFieldValue('dropPrice', productSelect.salePriceDrop)
    }
  }, [productSelect])

  useEffect(() => {
    if (warehouseSelected) {
      warhSelected(warehouseSelected.label)
      form.setFieldValue('warehouseId', warehouseSelected.value)
      form.setFieldValue('warehouseName', warehouseSelected.label)
      form.setFieldValue('warCompanyId', '1')

      //stokealo
      getProductsWar(
        String(`${warehouseSelected.value}`), companyGlobal ? String(companyGlobal.id) : String(), rolUserMaster
      )
    }
  }, [warehouseSelected])

  return <>
    <Spin spinning={loading}>
      <Form
        layout="horizontal"
        labelCol={{ span: 6 }}
        wrapperCol={{ span: 18 }}
        form={form}
        name="productSupplierSearch"
        labelWrap
        onFinish={onSubmit}
      >

        <Form.Item 
          name={['warehouseId']}
          label="Almacén">
          <Select
            onChange={handleWarehouseSelected}
            placeholder='Seleccionar un álmacen'
          >
            { warehousesData.map(w => {
              return (<>
                <Option key={w.value} value={w.value}>
                  {w.label}
                </Option>
              </>)
            })}
          </Select>
        </Form.Item>

        <Form.Item name={['productId']}
          label="Producto"
          rules={[{ required: true, message: 'Obligatorio' }]}>
          <Select
            onChange={handleChangeProduct}
            showSearch
            placeholder="Search to Select"
            optionFilterProp="label"
            filterOption={(input, option) =>
              (option?.label as string).toLowerCase().includes(input.toLowerCase())
            }
            options={productList}
          />
        </Form.Item>

        {variantList?.map((variant) =>
          <Form.Item
            key={variant.value}
            name={['optionsId', variant.label]}
            label={variant.label}
            rules={[{ required: true, message: 'Obligatorio' }]}>
            <Select
              placeholder="Seleccione una opción"
              allowClear
              options={variant.options}
            />
          </Form.Item>
        )}

        {skuSelect ? <>
          { warehouseSelected && warehouseSkuSelect?.id && warehouseSkuSelect.stockAvailable > 0 ? <>

            <Form.Item label=''>
              <Form.Item
                label="Stock Disponible"
                style={{ display: 'inline-block', width: 'calc(60%)' }}
              >
                <div style={{ color: '#0000FF' }}>{warehouseSkuSelect.stockAvailable}</div>
              </Form.Item>
              <Form.Item
                label="Stock en bodega"
                style={{ display: 'inline-block', width: 'calc(40%)' }}
              >
                <div style={{ color: '#0000FF' }}>{warehouseSkuSelect.stockStore}</div>
              </Form.Item>
            </Form.Item>
            
            <Form.Item
              label="Cantidad"
              name={['quantity']}
              rules={[{ required: true, message: 'Obligatorio' }]}>
              <InputNumber
                min={1}
                max={warehouseSkuSelect.stockAvailable <= 6 ? warehouseSkuSelect.stockAvailable : 6 } 
              />
            </Form.Item>
            <Form.Item 
              label="Precio Unitario"
              name={['price']}>
              <div>{skuSelect?.currency.symbol} {price}</div>
            </Form.Item>
            <Form.Item 
              label="Subtotal"
              name={['subtotal']}
              rules={[{ required: true, message: 'Obligatorio' }]}>
              <InputNumber min={0} value={subtotal}/>
            </Form.Item>

            <div style={{ textAlign: 'right' }}>
              <Button type="primary" htmlType="submit" size="large" disabled={!skuSelect}>
                Guardar
              </Button>
            </div>

          </> : <>
            <Space direction="vertical" style={{ width: '100%', marginBottom: 10 }}>
              <Alert
                message={`No hay stock disponible, contactarse con el PROVEDOR 
                ${skuSelect.company.setting.phone ? skuSelect.company.setting.phone : ''}`}
                type="info" />
            </Space>
          </>}
        </> : null}

        <Form.Item name={['productName']} hidden>
          <Input />
        </Form.Item>

        <Form.Item name={['sku']} hidden>
          <Input />
        </Form.Item>

        <Form.Item name={['dropPrice']} hidden>
          <Input />
        </Form.Item>

        <Form.Item name={['skuId']} hidden>
          <Input />
        </Form.Item>

        <Form.Item name={['warehouseName']} hidden>
          <Input />
        </Form.Item>

        <Form.Item name={['warCompanyId']} hidden>
          <Input />
        </Form.Item>

        <Form.Item name={['companyId']} hidden>
          <Input />
        </Form.Item>

      </Form>
    </Spin>
  </>
}

export default ProductSupplierAgencySearch
